<app-header [loading]="isLoading"></app-header>
<!-- <app-customers class="customers" [customerId]="customerId" (customerIdChange)="customerId = $event" (subscriptionEmitter)="selectCustomer($event)"></app-customers> -->
<div class="center m-3">
  <mat-form-field appearance="outline" class="advanced-style">
    <mat-label>Time Frame</mat-label>
    <mat-select [(value)]="selectedTimeFrame" (selectionChange)="changeTimeFrame($event)">
      <mat-option *ngFor="let frame of timeFrames" [value]="frame.value">
        {{ frame.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="advanced-style" *ngIf="selectedTimeFrame === 'advanced'">
    <input  [(ngModel)]="searchParams.start_date" matInput type="datetime-local" placeholder="Start Date">
  </mat-form-field>

  <mat-form-field class="advanced-style" *ngIf="selectedTimeFrame === 'advanced'">
    <input [(ngModel)]="searchParams.end_date" matInput type="datetime-local" placeholder="End Date">
  </mat-form-field>

  <button mat-raised-button class="advanced-style" (click)="checkAdvanced()"
  style="background-color: #00358E; color: white; margin-top: 1rem;"
  *ngIf="selectedTimeFrame === 'advanced'" 
  [disabled]="!isValidRange()" 
  [ngClass]="{ 'disabled-button': !isValidRange() }">Search</button>


  <div *ngIf="isLoading" class="loading">
    <img src="assets/load_gif.gif">
  </div>

  <div *ngIf="!isLoading" class="data-tiles-container">
    <!-- Letters Processed -->
    <div class="data-card">
      <div class="icon-section">
        <mat-icon aria-hidden="false" aria-label="Letter icon">mail_outline</mat-icon>
      </div>
      <div class="number-section">{{ stats.letters_processed }}</div>
      <div class="label-section">Letters Processed</div>
    </div>

    <!-- Pages Processed -->
    <div class="data-card">
      <div class="icon-section">
        <mat-icon aria-hidden="false" aria-label="Pages icon">description</mat-icon>
      </div>
      <div class="number-section">{{ stats.amount_of_pages }}</div>
      <div class="label-section">Pages Processed</div>
    </div>    
    
    <!-- Emails Sent -->
    <div class="data-card">
      <div class="icon-section">
        <mat-icon aria-hidden="false" aria-label="Email icon">send</mat-icon>
      </div>
      <div class="number-section"> {{ stats.emails_sent }} </div>
      <div class="label-section">Emails Sent</div>
    </div>
  
    <!-- Duplicates -->
    <div class="data-card">
      <div class="icon-section">
        <mat-icon aria-hidden="false" aria-label="Duplicates icon">file_copy</mat-icon>
      </div>
      <div class="number-section">{{ stats.duplicates }}</div>
      <div class="label-section">Duplicates</div>
    </div>
  
    <!-- Items Reviewed -->
    <div class="data-card">
      <div class="icon-section">
        <mat-icon aria-hidden="false" aria-label="Reviewed icon">check_circle</mat-icon>
      </div>
      <div class="number-section">{{ stats.items_reviewed }}</div>
      <div class="label-section">Items Reviewed</div>
    </div>
  
    <!-- Deleted Items -->
    <div class="data-card">
      <div class="icon-section">
        <mat-icon aria-hidden="false" aria-label="Deleted icon">delete</mat-icon>
      </div>
      <div class="number-section">{{ stats.deleted_items }}</div>
      <div class="label-section">Deleted Items</div>
    </div>
  </div>

</div>

<app-footer></app-footer>

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NameCasingPipe } from 'src/app/shared/pipe/name-casing.pipe';

@Component({
  selector: 'app-edit-failed-emails',
  templateUrl: './edit-failed-emails.component.html',
  styleUrls: ['./edit-failed-emails.component.scss']
})
export class EditFailedEmailsComponent implements OnInit {
  details: any= {};
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<EditFailedEmailsComponent>) { 
    const filterPipe = new NameCasingPipe();
    const beneficiary_name = filterPipe.transform(data.items.Items[0].beneficiary_name);
    const claim_number = filterPipe.transform(data.items.Items[0].claim_number);
    this.details = data.items.Items[0];
    this.details.claim_number = claim_number;
    this.details.beneficiary_name = beneficiary_name;
  }

  ngOnInit(): void {
  }
  submit(){
    console.log("this.details",this.details)
    this.notifyParent.emit(this.details)
    this.dialogRef.close()
  }
  cancel(){
    this.dialogRef.close()
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrls: ['./pdf-dialog.component.css']
})
export class PdfDialogComponent implements OnInit {
  
  constructor(public dialogRef: MatDialogRef<PdfDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  // displays presigned URL generated in Express as a PDF preview
  ngOnInit(): void {
    document.getElementById("pdf-viewer")!.setAttribute("data", `${this.data}#view=fitH`);
  }
}

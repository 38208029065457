import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './details/details.component';
// import { StatsComponent } from './stats.component';
import { OktaAuthGuard } from '@okta/okta-angular';
const routes: Routes = [
  { path: '', component: DetailsComponent, canActivate: [OktaAuthGuard] }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccessHistoryRoutingModule { }
import { Component, OnInit } from '@angular/core';
import UserAction from 'src/models/UserAction';
import { ApiService } from 'src/api.service';
import { data } from 'jquery';
import { PdfDialogComponent } from 'src/app/pdf-dialog/pdf-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateTimeService } from 'src/app/shared/services/datetime.service';
import { RootScopeService } from 'src/app/core/services/root-scope.service';

const PAGE_LENGTH:number = 25

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  isLoading:boolean = false;
  displayedColumns: string[] = ['action',  'item', 'dateOfAction', 'dateOfUpload', 'user', 'documentID'];
  dataSource:UserAction[]
  lastEvaluatedKeys:any[] = []
  pageSize:number = 25;
  length:number = 0;
  customerId:string = this.rootScope.globals.currentCustomer;
  private _isLastEvaluatedKey:boolean;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  

  constructor(private apiService:ApiService, public dialog: MatDialog, public datePipe:DatePipe, public dateTimeService: DateTimeService, public rootScope: RootScopeService) { }

  openPdf(document_id:string) {
    this.isLoading = true;
    this.apiService.getPdfPreview(document_id, "FAM").subscribe((data) => {
      this.isLoading = false
      const dialogRef = this.dialog.open(PdfDialogComponent, {
        width: '60%',
        height: '100%',
        position: {right: '0'},
        data: data.pdf,
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
      });
    })

  }


  // looks verbose w.r.t. the length variable, but it allows for a function pagination when the API returns only 25 results at a time, improving performance
  onPageChange(lastPageIndex:number|undefined,currentPageIndex:number) {
  
    const previousPageIndex = lastPageIndex ?? 0;
    this.isLoading = true
    let last_seen_user = undefined
    let last_seen_date = undefined;
    let action = undefined;

  
    if (previousPageIndex < currentPageIndex) {
      if (this.lastEvaluatedKeys.length != 0) {
        last_seen_user = this.lastEvaluatedKeys[this.lastEvaluatedKeys.length - 1].user,
        last_seen_date = this.lastEvaluatedKeys[this.lastEvaluatedKeys.length - 1].date_of_action,
        action = this.lastEvaluatedKeys[this.lastEvaluatedKeys.length - 1].action
      }
    } else if (previousPageIndex > currentPageIndex) {
      this.length -= PAGE_LENGTH
      if (currentPageIndex == 0) {
        // last_seen_user and last_seen_date stay undefined
      }
      else {
        last_seen_user = this.lastEvaluatedKeys[this.lastEvaluatedKeys.length - 2].user,
        last_seen_date = this.lastEvaluatedKeys[this.lastEvaluatedKeys.length - 2].date_of_action,
        action = this.lastEvaluatedKeys[this.lastEvaluatedKeys.length - 2].action
      }
    }

    this.apiService.getUserActions(this.customerId, last_seen_user, last_seen_date, action).subscribe((data) => { 
      
      if (data.lastEvaluatedKey) {
      if (previousPageIndex > currentPageIndex) {
        this.lastEvaluatedKeys.pop()
        // this.length = (currentPageIndex + 2) * PAGE_LENGTH
      }
      /*else {
        if (data.items.length == this.pageSize && data.lastEvaluatedKey && currentPageIndex == 0) {
          this.length = this.length + (2 * PAGE_LENGTH);
        }    
        else if (data.items.length == this.pageSize && data.lastEvaluatedKey) {
          this.length = this.length + PAGE_LENGTH;
        }
        else if (data.items.length < this.pageSize) {
          this.length = this.length - PAGE_LENGTH + data.items.length
        }
      }*/
      this.lastEvaluatedKeys.push(
        {
          'user': data.items[this.pageSize - 1].user,
          'date_of_action': data.items[this.pageSize - 1].date_of_action,
          'action': data.items[this.pageSize - 1].action
        }
      );
    }
    /*else {
      this.length = this.length - PAGE_LENGTH + data.items.length
    }*/
    this.length = data.totCount;
    this.dataSource = data.items;
    this.isLoading = false;
})
    }

   /*  selectCustomer(event:any) {
      this.onPageChange(0,0)
    } */

  
  ngOnInit(): void {
    this.onPageChange(0, 0);
  }

}

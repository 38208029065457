import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
/* import { ChartsModule } from '@progress/kendo-angular-charts';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout'; */
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ApiService } from 'src/api.service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeleteComponent } from './delete/delete.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { LettersTableComponent } from './letters-table/letters-table.component';
import { PdfDialogComponent } from './pdf-dialog/pdf-dialog.component';
import { SanitizeHtmlPipe } from './shared/pipe/sanitize-html.pipe';
import { BatchInfoComponent } from './batch-info/batch-info.component';
import { SharedModule } from './shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { StatsModule } from './modules/stats/stats.module';
import { AccessHistoryModule } from './modules/access-history/access-history.module';
import { CoreModule } from './core/core.module';
// import { CustomersComponent } from './shared/customers/customers.component';


const oktaAuth = new OktaAuth({
  issuer: environment.okta_issuer,
  clientId: environment.okta_client_id,
  redirectUri: environment.okta_redirect_uri
});


@NgModule({
  declarations: [
    AppComponent,
    LettersTableComponent,
    DocumentDetailsComponent,
    PdfDialogComponent,
    DeleteComponent,
    SanitizeHtmlPipe,
    BatchInfoComponent,
    // CustomersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
   /*  LayoutModule,
    GridModule,
    ChartsModule, */
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MatTooltipModule,
    OktaAuthModule,
    CoreModule,
    SharedModule,
    MatCheckboxModule,
    StatsModule,
    AccessHistoryModule
  ],
  exports: [
    SanitizeHtmlPipe
  ],
  providers: [
    DatePipe,
    ApiService,
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
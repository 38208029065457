<app-header [loading]="isLoading"></app-header>
<!-- <app-customers class="customers" [customerId]="customerId" (customerIdChange)="customerId = $event" (subscriptionEmitter)="selectCustomer($event)"></app-customers> -->
<div class="m-3">
    <table mat-table [dataSource]="dataSource">
  
      <!-- User Column -->
      <ng-container matColumnDef="user" class="user-column">
        <th mat-header-cell *matHeaderCellDef> User </th>
        <td mat-cell *matCellDef="let element"> {{element.user}} </td>
      </ng-container>
  
      <!-- Date of Action Column -->

      <ng-container matColumnDef="dateOfAction" class="date-of-action-column">
        <th mat-header-cell *matHeaderCellDef> Date of Action </th>
        <td mat-cell *matCellDef="let element"> {{ datePipe.transform((element.date_of_action), 'MMMM d, y \'at\' h:mm a') }} </td>
      </ng-container>
  
      <!-- Action Column -->
      <ng-container matColumnDef="action" class="action-column">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> {{element.action}} </td>
      </ng-container>
  
      <!-- Date of Upload Column -->
      <ng-container matColumnDef="dateOfUpload" class="date-of-upload-column">
        <th mat-header-cell *matHeaderCellDef> Date of Upload </th>
        <td mat-cell *matCellDef="let element"> {{ datePipe.transform(dateTimeService.convertUtcToLocal(element.date_of_upload), 'MMMM d, y \'at\' h:mm a') }} </td>
      </ng-container>
    
      <!-- Item Column -->
      <ng-container matColumnDef="item" class="item-column">
        <th mat-header-cell *matHeaderCellDef> Item </th>
        <td mat-cell *matCellDef="let element"> {{element.item}} </td>
      </ng-container>

      <ng-container matColumnDef="documentID" class="document-id-column">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element" class="iso-material-icons blue">
          <ng-container *ngIf="element.item!='Email-Template'">
           <mat-icon (click)="openPdf(element.document_id)" style="transform: scale(0.85); height: 24px;cursor:pointer" title="View Document">picture_as_pdf</mat-icon>
          </ng-container>
          <ng-container *ngIf="element.item=='Email-Template'">
            {{element.letter_type}}
          </ng-container>
          </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow >
        <td *ngIf="!isLoading" class="mat-cell no-data" [attr.colspan]="displayedColumns.length">
          No data to display
        </td>
      </tr>
    </table>
    <mat-paginator #paginator [pageIndex]=0 [pageSize]="pageSize" [length]="length" (page)="onPageChange($event.previousPageIndex, $event.pageIndex)"></mat-paginator>
  </div>
  
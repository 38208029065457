import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TableListParam } from 'src/app/models/custom-table/table-list-param';
import { customerDetails } from 'src/app/models/customers/details';
import { letterIds, DeletedLettersTable } from 'src/app/models/email-template/sent-email';
import { environment } from 'src/environments/environment';
import { RootScopeService } from 'src/app/core/services/root-scope.service';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {
  constructor(private http: HttpClient,private rootScope:RootScopeService) { }
  getDeletedLetters(customerId:string): Observable<DeletedLettersTable> {
    let url = environment.server.concat(`/deleted-letters`)
    let source =this.rootScope.globals.source
    url = url.concat(`/?customer_cd=${customerId}`)
    if(source!=undefined){
      url=url.concat(`&&source=${source}`)
    }
    console.log(url);
    return this.http.get<DeletedLettersTable>(url);
  }
 
  getCustomers(): Observable<customerDetails[]> {
    let url = environment.server.concat(`/customers`)
    return this.http.get<customerDetails[]>(url);
  }
  getLetterIds(customer_cd:string): Observable<letterIds[]> {
    let url = environment.server.concat(`/letter-ids/${customer_cd}`)
    return this.http.get<letterIds[]>(url);
  }
}

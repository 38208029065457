import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class ModalOptions implements NgbModalOptions {
  backdrop?: any = 'static';
  keyboard?: boolean = true;
  centered?: boolean = false;
  backdropClass?: string = '';
  windowClass?: string = '';
  container?: string = undefined;
  size?: any = 'sm';
}

export class ModalContentOptions {
  isModal?: boolean = false;
  viewAsModal?: boolean = false;
  isWarning?: boolean = false;
  isError?: boolean = false;
  isInfo?: boolean = false;
  actionComplete?: boolean = false;
  actionSuccess?: boolean = false;
  actionFailed?: boolean = false;
  isModalLoaded?: boolean = false;
  failedReason?: string = '';
  closeButtonText?: string = 'Close';
  actionButtonText?: string = 'OK';
  continueButtonText?: string = 'OK';
  headerText?: string = 'Proceed?';
  bodyText?: string = 'Perform this action?';
  bodyTextArr?: string[];
  ok?: any;
  close?: any;
  data?: any;
  acceptButtonText?: string = 'AcceptChanges';
  ignoreButtonText?: string = 'IgnoreChanges';
}

<div style="overflow-y: auto; scrollbar-width: none; -ms-overflow-style: none;background:white">
    <div style="padding:20px">
        <h4><span>Edit Document Details</span></h4>
        <form #detailsForm="ngForm">
            <div class="row">
                <div class="w-25">
                    <label for="lname">Beneficiary Name:</label>
                </div>
                <div class="w-75">
                    <input type="text" [(ngModel)]='details.beneficiary_name' required name="beneficiary_name" placeholder="Beneficiary Name..">
                </div>
            </div>
            <div class="row">
                <div class="w-25">
                    <label for="lname">Claim Number:</label>
                </div>
                <div class="w-75">
                    <input type="text" [(ngModel)]='details.claim_number' required id="claim_number" name="claim_number" placeholder="Claim Number..">
                </div>
            </div>
            <div class="row button-row">
                <div class="w-25">    
                </div>
                <div class="w-75">
                    <button class="pull-left" (click)="submit()" [disabled]="detailsForm.invalid">Save</button>
                    <button type="button" class="iso-cancel pull-left cancel"  (click)="cancel()">Cancel</button>
                </div>
            </div>
            <br>
        </form>
    </div>
  </div>
  
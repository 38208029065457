import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RootScopeService } from 'src/app/core/services/root-scope.service';
import { customerDetails } from 'src/app/models/customers/details';
import { ComponentServices } from '../../services/component-services';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() loading: boolean;
  public customersAvailable: customerDetails[] = [];
  source: string | undefined;
  customerId: string;
  menuItems: any;
  constructor( private router: Router, public rootScope: RootScopeService, public services: ComponentServices) { }

  ngOnInit(): void { 
    this.customersAvailable = this.rootScope.globals.customers;
    this.source = this.rootScope.globals.source;
    this.customerId = this.rootScope.globals.currentCustomer;
    this.menuItems = this.rootScope.globals.menuItems
  }
  navigate(value:any){
    this.router.navigate([value]);
  }
  public selectCustomer(str: string): void {
    if (this.rootScope.globals.currentCustomer != str) {
      this.services.switchCustomerActiveComponent(str);
      this.menuItems = this.rootScope.globals.menuItems
    }
  }
  /**
   * Function use to initiate the switching customer process.
   * @param custId: Customer Id Selected.
   */
  switchCustomer(custId: string) {
    this.services.InitializeCustomerSwitchingProcess(custId).then(
      () => {
        setTimeout(
          () => {
            this.ngOnInit();
          }, 5);
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './components/modal/modal-base/modal.component';
import { NameCasingPipe } from './pipe/name-casing.pipe';
import { CustomersComponent } from './customers/customers.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { EditFailedEmailsComponent } from './components/modal/edit-failed-emails/edit-failed-emails.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    NameCasingPipe,
    CustomersComponent,
    EditFailedEmailsComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    NgbModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NameCasingPipe,
    CustomersComponent
  ]
})
export class SharedModule { }

import { customerDetails } from "../customers/details";

export class Globals {
  currentUserName:string | undefined;
  currentCustomer: string;
  customers:customerDetails[];
  currentCustomerName:string;
  currentDocument?:any;
  source?:string;
  batchDocument?:any;
  menuItems?:any
}

import { Injectable } from '@angular/core';
import { Globals } from '../../models/common/Globals';


@Injectable({
  providedIn: 'root'
})
export class RootScopeService {
  globals = new Globals();
}

import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppStartService } from './services/app-start.service';

export function init_app(appStartService: AppStartService) {
  return () => appStartService.initializeApp();
}

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: init_app, multi: true, deps: [AppStartService] }
  ]
})
export class CoreModule { }

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DetailsService } from '../../deleted-letters/details/details.service';
import { Subscription } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/storage.service';
import { RootScopeService } from 'src/app/core/services/root-scope.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() subscriptionEmitter = new EventEmitter<Subscription>();
  customers: any[] = [];
  dataLoading = false;
  customerList = new FormControl('', [Validators.required]);
  customerIdSelected: any;
  public customerId:string;
  public source:string;
  isLoading :Boolean = false
  constructor(private detailsService: DetailsService, private router: Router,public storageService: StorageService,public rootScope: RootScopeService, private loginService: LoginService) { }

  ngOnInit(): void {
    const getCustomerDetailsSubscription: Subscription = this.detailsService
    .getCustomers()
    .subscribe(response => {
      this.customers = response;
      this.subscriptionEmitter.emit(getCustomerDetailsSubscription);
    });
  }
  loginOKTA(){
    this.loginService.setCredentials(this.customerId ,this.source).finally(
      () => {
        this.router.navigate(['entries']);
      }
    );
  }
}

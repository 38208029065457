import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RootScopeService } from '../services/root-scope.service';

@Injectable()
export class HomeAccessGuard implements CanActivate {

    constructor(private rootScope: RootScopeService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.rootScope.globals && this.rootScope.globals.currentCustomer) {
          return true;
        }
        // redirect user out if there's no Customer information.
        return this.router.parseUrl('auth');
    }
}


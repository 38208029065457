import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  public setSession(key: string, value: any): void {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  public getSession(key: string): any {
    return JSON.parse(window.sessionStorage.getItem(key) || '{}')
  }

  public destroySession(key: string): void {
    window.sessionStorage.removeItem(key);
  }

}

<div [ngClass]="getClass('modal-header')">
  <div class="modal-text">
    <i class="material-icons iso-modal-header-icons" [hidden]="!modalContentOptions.isError">error_outline</i>
    <i class="material-icons iso-modal-header-icons" [hidden]="!modalContentOptions.isWarning">warning</i>
    <i class="material-icons iso-modal-header-icons" [hidden]="!modalContentOptions.isInfo">info</i>
    <span class="iso-modal-header-text">
      {{modalContentOptions.headerText}}
    </span>
  </div>
</div>
<div class="modal-body">
  <p [hidden]="modalContentOptions.bodyTextArr && modalContentOptions.bodyTextArr.length > 0" [innerHtml]="modalContentOptions.bodyText"></p>
  <p [hidden]="!modalContentOptions.bodyTextArr || modalContentOptions.bodyTextArr.length == 0" *ngFor="let bodyText of modalContentOptions.bodyTextArr"
    [innerHtml]="bodyText" (click)="activeModal.dismiss()"></p>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close()" [hidden]="modalContentOptions.isError || modalContentOptions.isInfo" [ngClass]="getClass('button-action')">
    {{modalContentOptions.actionButtonText}}
  </button>
  <button [ngClass]="getClass('button-close')" (click)="activeModal.dismiss()">
    {{modalContentOptions.closeButtonText || 'CLOSE'}}
  </button>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { RootScopeService } from 'src/app/core/services/root-scope.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { DetailsService } from 'src/app/modules/deleted-letters/details/details.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  customers: any[] = []; // Adjust the type according to your data
  source:string;
  @Input() customerId: string;
  @Output() customerIdChange = new EventEmitter<string>();
  @Output() sourceChange=new EventEmitter<string>();
  @Output() subscriptionEmitter = new EventEmitter<Subscription>();

  constructor(private detailsService: DetailsService, public rootScope: RootScopeService, public storageService: StorageService) { }

  ngOnInit(): void {
    const event: MatSelectChange = {
      source: null as any,
      value: this.customerId ?? this.rootScope.globals.currentCustomer
    }
    this.loadCustomers(event);
  }

  loadCustomers(event?: MatSelectChange) {
    // default selection is Farmers
    const defaultEvent: MatSelectChange = {
      source: null as any,
      value: 'FAM'
    };
  
    // Use the provided event or the default event
    const effectiveEvent = event || defaultEvent;
    const getCustomerDetailsSubscription: Subscription = this.detailsService
      .getCustomers()
      .subscribe(response => {
        this.customers = response;
        this.customerId = effectiveEvent.value;
        this.rootScope.globals.customers = this.customers;
        this.storageService.setSession('globals', this.rootScope.globals);

       // Find the matching customer object based on the customerId
        const matchingCustomer = this.rootScope.globals.customers.find(customer => customer.customer_cd === this.customerId);
        this.source= matchingCustomer?.meta_source ? matchingCustomer.meta_source : '';

        //this.source=this.rootScope.globals.customers.filter(x=>x.customer_cd === this.customerId)[0].source;

        this.loadLetterIds(this.customerId,this.source);
        this.subscriptionEmitter.emit(getCustomerDetailsSubscription);
      }); 
  }
   
  loadLetterIds(customerId: string,source:string) {
    this.customerIdChange.emit(customerId);
    this.sourceChange.emit(source);
    // const getLetterDetailsSubscription: Subscription = this.detailsService
    // .getLetterIds(customerId)
    // .subscribe(response => {
    //   this.subscriptionEmitter.emit(getLetterDetailsSubscription);
    // }); 
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RootScopeService } from '../../core/services/root-scope.service';
import { StorageService } from '../../core/services/storage.service';
import { IApplicationMethodAccess } from 'src/app/models/interface/IAppMethodAccess';
// Make a shared entry point for all componsent services
@Injectable({
  providedIn: 'root'
})
export class ComponentServices {
  activeComponent: IApplicationMethodAccess;

  /* PLEASE AVOID PUT ANY OTHER LOGIC IN THIS CLASS. It's only a helper for all components to declare less stuff in their constructor.
      All requirements that involved usage of an object at global/casenotebook level should be handled by store.
  */
  constructor(public rootScope: RootScopeService,
                
              public storageService: StorageService,
              public http: HttpClient, public router: Router) { }

  public switchCustomerActiveComponent(custtId: string) {
    console.log("this.activeComponent",this.activeComponent)
    if (this.activeComponent.switchCustomer) {
     
      this.activeComponent.switchCustomer(custtId);
    }
  }

  public InitializeCustomerSwitchingProcess(custId: string) {
    const promise = new Promise<void>((resolve, reject) => {
        // Initiate the Customer Switching Process
        //this.store.dispatch(new SetCustomerSwitchStatus(true));
        this.rootScope.globals.currentCustomer = custId;
        this.rootScope.globals.currentCustomerName = this.rootScope.globals.customers.filter(x => x.customer_cd === custId)[0].customer_name;
        this.rootScope.globals.menuItems = this.rootScope.globals.customers.filter(x => x.customer_cd === custId)[0].menu_items;
        this.rootScope.globals.source = this.rootScope.globals.customers.filter(x=>x.customer_cd===custId)[0].meta_source;
        this.storageService.setSession('globals', this.rootScope.globals);
        //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/entries']);
        resolve();
   
    });
    return promise.then(
      () => {
        return Promise.resolve(true);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(private loginService: LoginService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }
  loginUrl = 'auth';
  pageMessage = '';
  ngOnInit(): void {
    this.loginService.clearCredentials().finally(
      () => {
        this.clearOktaCredentials();
        this.pageMessage = 'You logged out of your account.';
      }
    );
  }
  clearOktaCredentials() {
    // This is to clear the access token and id token set by Okta
    const tokenId = this._oktaAuth.getIdToken();
    if (tokenId) {
      // Sign users out of Okta
      this._oktaAuth.signOut();
      localStorage.clear();
      this.loginUrl =  'auth';
    }
  }

}

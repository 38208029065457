<div class="header">
    <div class="logoMenu">
    <div class="menu matItemMenu">
        <div mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="menu-icon hamberger-menu">menu</mat-icon>
        </div>
        <mat-menu #menu="matMenu">
           <!--  <button  *ngIf="source!='IE'" mat-menu-item routerLink='/entries'   routerLinkActive="active">
                <mat-icon class="white-icon">home</mat-icon>
                <span>Home</span>
            </button>
            <button  *ngIf="source!='IE'" mat-menu-item routerLink='/email-template' routerLinkActive="active">
                <mat-icon class="white-icon">email</mat-icon>
                <span>Email Template Management</span>
            </button>
            <button  *ngIf="source!='IE'" mat-menu-item routerLink='/sent-emails' routerLinkActive="active">
                <mat-icon class="white-icon">contact_mail</mat-icon>
                <span>Sent Emails</span>
            </button>
            <button  *ngIf="source!='IE'" mat-menu-item routerLink='/email-failures' routerLinkActive="active">
                <mat-icon class="white-icon">sms_failed</mat-icon>
                <span>Email Failures</span>
            </button>
            <button  *ngIf="source!='IE'" mat-menu-item routerLink='/stats' routerLinkActive="active">
                <mat-icon class="white-icon">format_list_numbered</mat-icon>
                <span>Statistics</span>
                </button>
            <button  *ngIf="source!='IE'" mat-menu-item routerLink='/access-history' routerLinkActive="active">
                <mat-icon class="white-icon">history</mat-icon> 
                <span>Access History</span>
            </button>
            <button  *ngIf="source!='IE'" mat-menu-item routerLink='/deleted-letters' routerLinkActive="active">
                <mat-icon class="white-icon">folder_delete</mat-icon>
                <span>Deleted Letters</span>
            </button>
            <button *ngIf="source!='IE'" mat-menu-item routerLink='/failed-uploads' routerLinkActive="active">
                <mat-icon class="white-icon">cloud_off</mat-icon> 
                <span>Failed Uploads</span>
            </button>   -->
            <div *ngFor="let menu of menuItems">
                <button  mat-menu-item routerLink='{{menu.router_link}}' routerLinkActive="active">
                    <mat-icon class="white-icon">{{menu.icon}}</mat-icon> 
                    <span>{{menu.name}}</span>
                </button>
            </div>
            
            <!-- <button *ngIf="source=='IE'" mat-menu-item routerLink='/index-expert-details' routerLinkActive="active">
                <mat-icon class="white-icon">view_list</mat-icon> 
                <span>List unstructured documents</span>
            </button>
            <button *ngIf="source=='IE'" mat-menu-item routerLink='/unclassified-documents' routerLinkActive="active">
                <mat-icon class="white-icon">description</mat-icon> 
                <span>Unclassified Documents</span>
            </button>     -->          
        </mat-menu>
    </div>
        
    
            <img class="logo" src="assets/verisk_logo.svg" style="margin-left: 25%;">
    
            <div id="customer-select" class="customer-select pointer-cursor-on-hover" [matMenuTriggerFor]="userOptionsMenu" aria-label="User options menu">
                <img id="customer-detail__icon" src="assets/workspaces_88f393.svg" />
                <div class="spacing-8"></div>
                <span  class="text-h4-heading customer-text">{{rootScope.globals.currentCustomerName}}</span>
                <svg width="24" height="24"  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#88f393"  d="M13.2333 7.74167L9.99998 10.975L6.76665 7.74167C6.44165 7.41667 5.91665 7.41667 5.59165 7.74167C5.26665 8.06667 5.26665 8.59167 5.59165 8.91667L9.41665 12.7417C9.74165 13.0667 10.2667 13.0667 10.5917 12.7417L14.4166 8.91667C14.7416 8.59167 14.7416 8.06667 14.4166 7.74167C14.0916 7.42501 13.5583 7.41667 13.2333 7.74167Z"/>
                </svg>
                <mat-menu #userOptionsMenu="matMenu" class="customer-select-menu" xPosition="before">
                    <div id="customer-select-menu__title" class="text-h5-heading">{{'Choose Environment'}}</div>
                    <button mat-menu-item class="customer-select-menu__button" *ngFor="let customer of customersAvailable">
                        <div class="customer-select-menu__button__text text-body-small text-black" (click)="selectCustomer(customer.customer_cd)">
                            <div>{{customer.meta_source}} - {{customer.customer_name}}</div>
                            <div class="customer-select-menu__button__text__indicator">
                                <div class="spacing-16">
                                    <img class="customer-select-menu__button__text__indicator__icon" *ngIf="rootScope.globals.currentCustomer == customer.customer_cd" src="assets/check.svg" />
                                </div>
                            </div>
                        </div>
                    </button>
                    <div id="customer-select-menu__logout"></div>
                    <button mat-menu-item class="customer-select-menu__button">
                        <div routerLink='/auth/logout' class="text-body-small text-black">{{'Logout'}}</div>
                    </button>
                </mat-menu>
            </div>
    
            <div id="customer-detail">
                <img id="customer-detail__icon" src="assets/person.svg" />
                <div class="spacing-8"></div>
                <div id="customer-detail__info">
                    <div>
                        <div class="text-h4-heading text-white">{{'Welcome,'}} {{rootScope.globals.currentUserName}}</div>
                    </div>
                </div>
            </div>
        </div>

    
</div>
<!-- LOADING ICON -->
<div *ngIf="loading" class="loading">
    <img src="assets/load_gif.gif">
</div>



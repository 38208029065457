import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { StatsRoutingModule } from './stats-routing.module';
import { DetailsComponent } from './details/details.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
// import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';  
// import { TimepickerModule}  from 'ngx-bootstrap/timepicker';
// import { MatTimepickerModule } from 'mat-timepicker';

// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
// import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
// import { MatAnchor } from '@angular/material/button';

@NgModule({
  declarations: [
    DetailsComponent
  ],
  imports: [
    CommonModule,
    StatsRoutingModule,
    MatButtonModule,
    FormsModule,
    SharedModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    // AngularEditorModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // TimepickerModule
    // NgxMaterialTimepickerModule,
    // NgxMatDatetimePickerModule  
  ]
})
export class StatsModule { }

<div class="rnav-login-viewport">
    <div class="rnav-login-header">
      <div class="user-menu">
        <div class="spacing-40"></div>
        <div class="user-account">
          <div style="width: 24px;height: 24px;">
            <img  src="assets/person.svg" />
          </div>
          <div class="spacing-8"></div>
          <div class="text text-h4-heading">Login</div>
          <div class="spacing-8"></div>
        </div>
      </div>
      <div class="logo">
        <img class="image-logo" src="assets/verisk_logo.svg">
      </div>
    </div>
    
    <div class="rnav-login-panel">
      <div >
        <div id="login" name="login" >
          <div class="rnav-login-container">
            <div class="heading text-h2-heading">Login</div>
            <div class="select-customer">
              <div  class="text-body">Select the customer to access the application.</div>
              <div style="width: 380px; height: 44px;">
                <app-customers class="customers"  [customerId]="customerId" (customerIdChange)="customerId = $event" (sourceChange)="source = $event"></app-customers>
              </div>
            </div>
            <button [disabled]="!customerId" [ngClass]="{'disabled-on-loading':dataLoading}" (click)="loginOKTA()"
               kendoButton themeColor="largePrimary">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="rnav-login-container-background"></div>
    <app-footer></app-footer>
  </div>
   
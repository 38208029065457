import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import { ApiService } from 'src/api.service';
import {MatDialogModule} from '@angular/material/dialog';
import { DocumentDetailsComponent } from '../document-details/document-details.component';

@Component({
  selector: 'app-batch-info',
  templateUrl: './batch-info.component.html',
  styleUrls: ['./batch-info.component.css']
})
export class BatchInfoComponent implements OnInit {

  public displayedColumns: string[] = ['category', 'value']; 
  public dataSource:any;

  // NOT IN USE

  constructor(
    private route:ActivatedRoute, 
    private apiService:ApiService,
     public dialog: MatDialogModule,
     public dialog_: MatDialog,
     public dialogRef: MatDialogRef<BatchInfoComponent>,
     @Inject(MAT_DIALOG_DATA) public data: string, 
     ) { }

  ngOnInit(): void {
    let display_data = []
    console.log(this.data.length)
    for (let i = 0; i < this.data.length; i++) {
      display_data.push({ category: i, value: this.data[i] })
    }
    this.dataSource = display_data
  }

  public getDetails(document_id:string) {
    console.log(document_id)
    this.apiService.getDocumentData(document_id).subscribe((data) => {
      const dialogRef = this.dialog_.open((DocumentDetailsComponent), {
        width: '60%',
        data: data,
      })
    })

  }

}

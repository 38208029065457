import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AppComponent } from './app.component';
import { LettersTableComponent } from './letters-table/letters-table.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { AuthModule } from './modules/auth/auth.module';
import { LogoutComponent } from './modules/auth/logout/logout.component';
import { HomeAccessGuard } from './core/guards/HomeAccessGuard';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: 'auth', loadChildren: () => AuthModule, canActivate: [OktaAuthGuard] },
  { path: 'entries', component: LettersTableComponent, canActivate: [OktaAuthGuard,HomeAccessGuard]},
  { path: 'entries/:document_id/:letter_id', component: DocumentDetailsComponent,  canActivate: [HomeAccessGuard]},
  { path: 'email-template', loadChildren: () => import('./modules/email-template/email-template.module').then(m => m.EmailTemplateModule), canActivate: [HomeAccessGuard] },
  { path: 'sent-emails', loadChildren: () => import('./modules/sent-emails/sent-emails.module').then(m => m.SentEmailsModule), canActivate: [HomeAccessGuard] },
  { path: 'email-failures', loadChildren: () => import('./modules/email-failures/email-failures.module').then(m => m.EmailFailuresModule), canActivate: [HomeAccessGuard] },
  { path: 'stats', loadChildren: () => import('./modules/stats/stats.module').then(m => m.StatsModule), canActivate: [HomeAccessGuard] },
  { path: 'access-history', loadChildren: () => import('./modules/access-history/access-history.module').then(m => m.AccessHistoryModule), canActivate: [HomeAccessGuard] },
  { path: 'deleted-letters', loadChildren: () => import('./modules/deleted-letters/deleted-letters.module').then(m => m.DeletedLettersModule), canActivate: [HomeAccessGuard] },
  { path: 'failed-uploads', loadChildren: () => import('./modules/unhandled-exceptions/unhandled-exceptions.module').then(m => m.UnhandledExceptionsModule) },
  { path: 'index-expert-details', loadChildren: () => import('./modules/index-expert/index-expert.module').then(m => m.IndexExpertModule) },
  { path: 'unclassified-documents', loadChildren: () => import('./modules/unclassified-documents/unclassified-documents.module').then(m => m.UnclassifiedDocumentsModule) },
  { path: 'logout', component: LogoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [HomeAccessGuard]
})
export class AppRoutingModule { }
import { Injectable } from '@angular/core';
import { Globals } from '../../models/common/Globals';
import { StorageService } from './storage.service';
import { RootScopeService } from './root-scope.service';

@Injectable({
  providedIn: 'root'
})
export class AppStartService {
    constructor(private storageService: StorageService, private rootScope: RootScopeService) { }

    public initializeApp(): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.rootScope.globals = (this.storageService.getSession('globals') as Globals) || new Globals();
            resolve(true);
        });
        return promise.then(
        () => {
            return true;
        },
        () => {
            return false;
        }
        );
    }
}



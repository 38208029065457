// for search reasons, names are stored in all caps.
// this pipe outputs the names in a more readable format for the interface

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameCasing' })
export class NameCasingPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Split by space and comma, keeping the delimiters
    const segments = value.split(/([ ,])/g);

    return segments.map((segment) => {
      // If the segment is a space or comma, return it as is
      if (segment === ' ' || segment === ',') return segment;

      // Otherwise, capitalize the first letter and make the rest lowercase
      return segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
    }).join('');
  }
}

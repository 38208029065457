import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import { ApiService } from 'src/api.service';
import {MatDialogModule} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DateTimeService } from '../shared/services/datetime.service';
import { RootScopeService } from '../core/services/root-scope.service';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.css']
})
export class DocumentDetailsComponent implements OnInit {

  constructor(
    private route:ActivatedRoute, 
    public rootScope: RootScopeService,
    private apiService:ApiService,
     public dialog: MatDialogModule,
     public dialogRef: MatDialogRef<DocumentDetailsComponent>,
     private datePipe:DatePipe,
     private dateTimeService: DateTimeService,
     @Inject(MAT_DIALOG_DATA) public data: string, 
     ) { }

  public document_id:string;
  public letter_id:string;
  public displayedColumns: string[] = ['category', 'value'];
  public dataSource:any;
  customerId: string;
  source:string|undefined;
async ngOnInit(): Promise<void> {
  this.customerId = this.rootScope.globals.currentCustomer // default
  this.source=this.rootScope.globals.source
  let temp_data:any
  temp_data = this.data

  let email_sent:boolean
  let ie_status:any
  email_sent = temp_data.items.Items[0].email_sent
  ie_status=temp_data.items.Items[0].ie_status;
  console.log(`EMAIL SENT: ${email_sent}`)

  let email_sent_or_failed_timestamp:any;

  if (email_sent) {
    let response = await this.apiService.getEmailSuccessTimestamp(temp_data.items.Items[0].document_id).toPromise();
    email_sent_or_failed_timestamp = response.timestamp?.send_datetime;
    // await this.apiService.getEmailSuccessTimestamp(temp_data.items.Items[0].document_id).subscribe((data) => {
    //   email_sent_or_failed_timestamp = data.timestamp.send_datetime
    // })  
  }

  if (!email_sent) {
    // emails failures table doesn't store the document_id, instead stores a "doc_payload" which is formatted as doc_id,date_of_upload,customer_cd without spaces
    let doc_payload = `${temp_data.items.Items[0].document_id},${temp_data.items.Items[0].date_of_upload},${temp_data.items.Items[0].customer_cd}`
    let response = await this.apiService.getEmailFailureTimestamp(doc_payload).toPromise();
    email_sent_or_failed_timestamp = response.timestamp?.date_failed;
    // email_sent_or_failed_timestamp = response.timestamp.send_datetime;
    // await this.apiService.getEmailFailureTimestamp(temp_data.items.Items[0].document_id).subscribe((data) => {
    //   email_sent_or_failed_timestamp = data.timestamp.send_datetime
    // })
  }
  let fields = temp_data.items.Items[0]

  let display_data = []
  // since each document gives different data, we have to use these conditionals to see what data we're getting
  // depending on the data we get, add it to the display_data array, to be shown in table form on the UI
  
  if (fields['beneficiary_name'] != undefined) {
    display_data.push({ category: 'Beneficiary Name', value: fields['beneficiary_name'] })
  }

  if (fields['claim_number'] != undefined) {
    display_data.push({ category: 'Claim Number', value: fields['claim_number'] })
  }

  if (fields['letter_id'] != undefined) {
    display_data.push({ category: 'Letter ID', value: fields['letter_id'] })
  }
  if(fields['upload_id']!=undefined){
    display_data.push({category: 'Upload ID', value: fields['upload_id']})
  }
  if (fields['client_type'] != undefined && this.customerId=='GB') {
    display_data.push({ category: 'Client Type', value: fields['client_type'] })
  }

  if (fields['date_of_incident'] != undefined) {
    display_data.push({ category: 'Date of Incident', value: fields['date_of_incident'] })
  }

  if (fields['date_of_letter'] != undefined) {
    display_data.push({ category: 'Date of Letter', value: fields['date_of_letter'] })
  }

  if (fields['date_of_upload'] != undefined) {
    let date = new Date(fields['date_of_upload'])
    let dateString = date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});    
    display_data.push({ category: 'Date of Upload', value: dateString })
  }

  if (fields['case_identification_number'] != undefined) {
    if (fields['case_identification_number'].includes("Case Identification Number:")) {
      display_data.push({ category: "Case ID Number", value: fields['case_identification_number'].substring(27) })
    }
    else {
      display_data.push({ category: "Case ID Number", value: fields['case_identification_number'] })

    }
  }


  if (fields['additional_information'] != undefined) {


    if (fields['additional_information']['Insurer Policy Number'] != undefined) {
      display_data.push({ category: 'Insurer Policy Number', value: fields['additional_information']['Insurer Policy Number']['S'] })
    }

    if (fields['additional_information']['Medicare ID'] != undefined) {
      display_data.push({ category: 'Medicare ID', value: fields['additional_information']['Medicare ID']['S'] })
    }

    if (fields['additional_information']['CRC Recovery ID Number'] != undefined) {
      display_data.push({ category: 'CRC Recovery ID', value: fields['additional_information']['CRC Recovery ID Number']['S'] })
    }

  
  }

  if (fields['bucket_name'] != undefined) {
    display_data.push({ category: 'Bucket Name', value: fields['bucket_name'] })
  }


  if (fields['folder_name'] != undefined) {
    display_data.push({ category: 'Folder', value: fields['folder_name'] })
  }

  if (fields['duplicates'] != undefined) {
    let length = fields['duplicates'].length
    display_data.push({ category: 'Duplicates', value: length - 1 })
  }
  
  if (fields['document_control_number'] != undefined) {
    if (fields['document_control_number'].includes("Document Control Number:")) {
      display_data.push({ category: 'Document Control #', value: fields['document_control_number'].substring(24)})
    }
    else {
      display_data.push({ category: 'Document Control #', value: fields['document_control_number']})
    }
  }


  if (fields['document_id'] != undefined) {
    display_data.push({ category: 'Document ID', value: fields['document_id'] })
  }

  if (fields['batch_id'] != undefined) {
    display_data.push({category: 'Batch ID', value: fields['batch_id']})
  }

  if (fields['batch_file_name'] != undefined) {
    display_data.push({ category: 'Batch File', value: fields['batch_file_name'] })
  }

  if(this.source==='IE'){
    if (ie_status) {
      display_data.push( {category: 'Status', value: temp_data.items.Items[0].ie_status})    
      display_data.push({category: 'Status Date Time', value: this.datePipe.transform(this.dateTimeService.convertUtcToLocal(temp_data.items.Items[0].ie_status_datetime), 'MMMM d, y \'at\' h:mm a')})   
    }
    else {
      display_data.push({category:'Status', value:''})
      display_data.push({category: 'Status Date Time', value: ''})
    }
    
    /* if(email_sent_or_failed_timestamp){
      display_data.push({category: 'Status Date Time', value: this.datePipe.transform(this.dateTimeService.convertUtcToLocal(email_sent_or_failed_timestamp), 'MMMM d, y \'at\' h:mm a')})
    } else{
      display_data.push({category: 'Status Date Time', value: ''})
    } */
  }
  else{
  if (email_sent) {
    display_data.push( {category: 'Email Sent', value: 'Yes'})
    if(email_sent_or_failed_timestamp){
      display_data.push({category: 'Email Date', value: this.datePipe.transform(this.dateTimeService.convertUtcToLocal(email_sent_or_failed_timestamp), 'MMMM d, y \'at\' h:mm a')})
    } else{
      display_data.push({category: 'Email Date', value: ''})
    } 
  }

  if (!email_sent) {
    display_data.push({category:'Email Sent', value:"No"})
    if(email_sent_or_failed_timestamp){
      display_data.push({category:"Date of Failure", value: this.datePipe.transform(this.dateTimeService.convertUtcToLocal(email_sent_or_failed_timestamp), 'MMMM d, y \'at\' h:mm a')})
    } else{
      display_data.push({category:"Date of Failure", value: ''})
    } 
  }
}


    this.dataSource = display_data
  }
}